@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Roboto', sans-serif;
}

.loading,
.disabled {
  opacity: 0.5 !important;
  pointer-events: none;
}

.route {
  padding: 20px;
  max-width: 1000px;
  margin: auto;
}

.space-items-x > *:not(:last-child) {
  margin-right: 8px;
}

.space-items-y > *:not(:last-child) {
  margin-bottom: 8px;
}

.space-items-y-lg > *:not(:last-child) {
  margin-bottom: 20px;
}

.input:focus-within label {
  color: #038bbb;
}

.input-contained {
  border-bottom-color: #038bbb;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: solid 2px #9e9e9e;
}

.input-contained:focus-within {
  border-bottom-color: #038bbb;
}

.loading-dot {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #9e9e9e;
  animation-name: LoadingDotExpand;
  animation-duration: 1.75s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.loading-dot:first-child {
  left: -10px;
  animation-delay: 0s;
}

.loading-dot:last-child {
  right: -10px;
  animation-delay: 0.4s;
}

@keyframes LoadingDotExpand {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

pre.cert-json {
  line-height: 20px !important;
}

.cert-json * {
  font-family: 'Roboto Mono', monospace !important;
  font-size: 14px !important;
}
